<template>
  <el-card
    shadow="never" class="text-left">
    <el-row :gutter="20">
      <el-col :span="3">
        <el-button
          type="primary"
          @click="handleAdd">
          发送消息
        </el-button>
      </el-col>
      <el-col :span="17">
        <el-form :inline="true" label-width="80px">
          <el-form-item>
            <el-input
              v-model="searchKeyword"
              placeholder="请输入关键字"
              suffix-icon="el-icon-search" />
          </el-form-item>
          <el-form-item>
            <el-select v-model="searchTarget" placeholder="请选择">
              <el-option
                v-for="item in targetList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="searchType" placeholder="请选择">
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="handleRefresh">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import options from '@/utils/options'

export default {
  props: {
    type: {
      type: String,
      require: true
    },
    keyword: {
      type: String,
      require: true
    },
    target: {
      type: String,
      require: true
    },
  },
  computed: {
    searchType: {
      set (str) {
        this.handleQuery({
          type: str
        })
      },
      get () {
        return this.type
      }
    },
    searchTarget: {
      set (str) {
        this.handleQuery({
          target: str
        })
      },
      get () {
        return this.target
      }
    },
    searchKeyword: {
      set (str) {
        this.handleQuery({
          keyword: str
        })
      },
      get () {
        return this.keyword
      }
    },
    targetList() {
      let arr = options.pushTargetList.map(p => {
        return p
      })
      arr.unshift({ value: '', label: '全部发送对象' })
      return arr
    },
    typeList() {
      let arr = options.pushTypeList.map(p => {
        return p
      })
      arr.unshift({ value: '', label: '全部消息类型' })
      return arr
    },
  },
  methods: {
    handleQuery (obj) {
      this.$emit('handleQuery', obj)
    },
    handleRefresh () {
      this.handleQuery({
        page: 1
      })
      this.$emit('handleRefresh')
    },
    handleAdd () {
      this.$router.push('push/add')
    },
  }
}
</script>
<style lang="scss" scoped>
.el-form-item{margin-bottom: 0!important;}
</style>
