<template>
  <div>
    <el-table
      ref="listTable"
      :data="tableData">
      <el-table-column
        prop="title"
        label="标题"
        min-width="300"
        align="left" />
      <el-table-column
        prop="content"
        label="推送內容"
        min-width="300"
        align="left" />
      <el-table-column
        label="消息类型"
        min-width="150"
        align="center">
        <template slot-scope="scope">
          {{ handleType(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column
        label="发送对象"
        min-width="150"
        align="center">
        <template slot-scope="scope">
          {{ handleTarget(scope.row) }}
        </template>
      </el-table-column>
      <!-- <el-table-column
        label="发送对象ID"
        min-width="150"
        align="center">
        <template slot-scope="scope">
          {{ scope.row.target_id }}
        </template>
      </el-table-column> -->
      <el-table-column
        prop="updated_at"
        label="时间"
        min-width="150"
        align="center" />
      <el-table-column
        label="操作"
        fixed="right"
        min-width="200"
        align="center">
        <template slot-scope="scope">
          <el-button-group>
            <el-tooltip
              effect="dark"
              content="查看"
              placement="top">
              <el-button
                type="primary"
                icon="el-icon-view"
                @click="handleView(scope.row)" />
            </el-tooltip>
            <!-- <el-tooltip
              effect="dark"
              content="修改"
              placement="top">
              <el-button
                :disabled="!editable"
                type="success"
                icon="el-icon-edit"
                @click="handleEdit(scope.row)" />
            </el-tooltip> -->
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <FullScreenDialog
      type="push"
      :visible="showPreview"
      :contain="previewContent"
      :memberInfo="memberInfo"
      @toggleDialog="toggleDialog" />
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import options from '@/utils/options'
import { pushMemberInfo } from '@/api/admin/push'
import FullScreenDialog from '@/components/admin/full-screen-dialog'

export default {
  components: {
    FullScreenDialog
  },
  data() {
    return {
      previewContent: null,
      showPreview: false,
      memberInfo: null,
    }
  },
  props: {
    tableData: {
      type: Array,
      required: true
    },
    editable: {
      type: Boolean,
      required: true
    },
  },
  methods: {
    handleView(row) {
      this.previewContent = row
      this.showPreview = true
      if (row.target_id !== '0') {
        pushMemberInfo(row.target_id).then(res => {
          this.memberInfo = res.data
        })
      } else {
        this.memberInfo = null
      }
    },
    toggleDialog (boo) {
      this.showPreview = boo
    },
    handleMember(row) {
      // console.log(row.target_id)
      // pushMemberInfo(row.target_id).then(res => {
      //   switch (res.status) {
      //     case resStatus.OK:
      //       console.log(res)
      //       break
      //   }
      // }).catch(err => {
      //   this.$message.error(err)
      // })
    },
    handleType(row) {
      let data = ``
      options.pushTypeList.forEach(key => {
        if (key.value === row.type)
          data = key.label
      })
      return data
    },
    handleTarget(row) {
      let data = ``
      options.pushTargetList.forEach(key => {
        if (key.value === row.target)
          data = key.label
      })
      return data
    },
    handleEdit(row) {
      this.$router.push({
        name: 'AdminMemberPushEdit',
        params: {
          id: row.id
        }
      })
    },
  }
}
</script>
